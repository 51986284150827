<template>
  <b-form-group :description="hint" :label="label" :valid-feedback="validFeedback" :state="state"
    :invalid-feedback="invalidFeedback" :label-cols-lg="horizontal ? 3 : 12" :label-cols-xl="horizontal ? 3 : 12"
    :content-cols-lg="horizontal ? 9 : 12" :content-cols-xl="horizontal ? 6 : 12">
    <b-input-group>
      <date-picker ref="picker" :value="value" class="date-picker form-control-solid" :config="customOptions" :wrap="wrap"
        :placeholder="placeholder" @dp-change="handleChange"></date-picker>
      <template #append>
        <b-btn class="w-100 h-100 d-flex justify-content-center align-items-center" icon @click.prevent="handleToggle">
          <i class="text-dark-50 ki ki-calendar-2 ml-1"></i>
        </b-btn>
      </template>
    </b-input-group>
  </b-form-group>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
import moment from "moment";
import { BFormMixin } from "@/core/mixins";
import { mapGetters } from "vuex";

export default {
  name: "DatePicker",
  components: {
    datePicker,
  },

  mixins: [BFormMixin],

  props: {
    value: {
      default: null,
      validator(value) {
        return (
          value === null ||
          value instanceof Date ||
          typeof value === "string" ||
          value instanceof String ||
          value instanceof moment
        );
      },
    },
    // http://eonasdan.github.io/bootstrap-datetimepicker/Options/
    overrides: {
      type: Object,
      default: () => ({}),
    },
    /**
     * You can set this to true when component is wrapped in input-group
     * Note: inline and wrap mode wont work together
     */
    wrap: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Select a Date",
    },
    placeholderString: {
      type: String,
      default: "",
    }
  },

  computed: {
    ...mapGetters("user", ["date_format"]),

    placeholder() {
      if(this.placeholderString != ""){
        return this.placeholderString;
      }
      return this.date_format;
    },

    customOptions() {
      return {
        extraFormats: ["YYYY-MM-DD"],
        format: this.date_format,
        ...this.overrides,
      };
    },
  },

  mounted() {
    if (!this.value) this.$refs.picker.dp.clear();
  },

  methods: {
    handleChange(e) {
      const { date } = e
      if (date) {
        this.$emit('input', date.format("YYYY-MM-DD"))
      } else {
        this.$emit('input', null)
      }
    },

    handleToggle() {
      this.$refs.picker.$data.dp.toggle();
    },
  },
};
</script>
