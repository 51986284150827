var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    attrs: {
      "description": _vm.hint,
      "label": _vm.label,
      "valid-feedback": _vm.validFeedback,
      "state": _vm.state,
      "invalid-feedback": _vm.invalidFeedback,
      "label-cols-lg": _vm.horizontal ? 3 : 12,
      "label-cols-xl": _vm.horizontal ? 3 : 12,
      "content-cols-lg": _vm.horizontal ? 9 : 12,
      "content-cols-xl": _vm.horizontal ? 6 : 12
    }
  }, [_c('b-input-group', {
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('b-btn', {
          staticClass: "w-100 h-100 d-flex justify-content-center align-items-center",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.handleToggle.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "text-dark-50 ki ki-calendar-2 ml-1"
        })])];
      },
      proxy: true
    }])
  }, [_c('date-picker', {
    ref: "picker",
    staticClass: "date-picker form-control-solid",
    attrs: {
      "value": _vm.value,
      "config": _vm.customOptions,
      "wrap": _vm.wrap,
      "placeholder": _vm.placeholder
    },
    on: {
      "dp-change": _vm.handleChange
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }